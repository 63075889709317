

* {
  padding: 0;
  margin: 0;
}

body{
  font-family: 'Open Sans';
  background-color: #161d23 !important;
  position: relative;
}
body > iframe{
display: none;
}
html {
  scroll-behavior: smooth;
  
}

body,
textarea,
input {
  padding: 0;
  margin: 0;
  line-height: 1.5;
}

a {
  display: inline-block;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  color: #fff;
  font-weight: bold !important;
  font-family: 'Satoshi' !important;
}

h1,
h2{
  font-size: 64px !important;
  line-height: 1.35 !important;
  font-weight: 900 !important;
}

h3{
  font-size: 32px;
  line-height: 1.2;
}

p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-size: 18px;
  color: #fff;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

img{
  max-width: 100%;
}

.common-btn{
  background: linear-gradient(108.23deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  font-weight: 500;
  color: #fff !important;
  font-size: 18px;
  padding: 10px 25px; 
  min-width: 200px;
  text-align: center;
  transition: all ease-in-out 0.2s;
  border: 0;
}

body .wrapper{
  overflow: hidden;
}

/* Header */

header{
  padding:30px 0;
  position: relative;
  z-index: 2;
}


header .navbar{
  padding: 0 !important;
  gap: 30px;
}

header .navbar ul{
  display: flex;
  align-items: center;
  margin: 0;
}

header .navbar ul li{
  margin: 0 20px;
}

header .navbar ul li a{
  color: #fff;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
  font-size: 18px;
}

header .navbar ul li a:hover{
background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
/* Casino Intro */

.casino-intro{
  padding: 80px 0 100px;
  position: relative;
}


.casino-intro .content{
  max-width: 600px;
}

.casino-intro h1{
  margin-bottom: 32px;
  max-width: 600px;
}

.casino-intro canvas{
  margin-left: -200px;
}

.casino-intro p{
  margin-bottom: 40px;
}

.mint h2{
  margin-bottom: 40px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}


.pattern{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  z-index: -1;
}

.lottery-list .head{
  text-align: center;
  margin-bottom: 40px;
}

.lottery-list .head h2{
  margin-bottom: 30px;
}

.lottery-box{
  background: #1D2833;
  padding: 15px 15px 0;
  margin-bottom: 20px;
}

.lottery-box img{
  width: 100%;
  margin-bottom: 15px;
}
.lottery-box .info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.lottery-box .info span{
  color: #A9AAAD;
  font-size: 14px;
  display: block;
}
.lottery-box .info > div:last-child{
  text-align: right;
}
.lottery-box .common-btn{
  border-radius: 0;
  min-width: inherit;
  padding: 5px 20px;
  border-radius: 1px;
  margin-bottom: 10px;
}
.lottery-box .detail{
margin-left: -15px;
width: calc(100% + 30px);
}

.lottery-box .detail a{
display: block;
width: 100%;
text-align: center;
font-size: 18px;
padding: 15px;
color: #fff;
background: linear-gradient(108.23deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}
.lottery-box h3{
  font-size: 20px;
  min-height: 37px;
  line-height: 1.3;
}
.timer{
  background: linear-gradient(108.23deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  padding: 5px 15px 12px;
  margin-left: -15px;
  width: calc(100% + 30px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer > p{
  color: #000;
  font-size: 32px;
  font-weight: 600;
  margin: 0 10px;
  text-align: center;
}

.timer > p span{
  display: block;
  color: #fff;
  font-size: 14px;
}

footer .inner{
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
}

footer .inner .left p{
  margin-top: 24px;
}

footer .inner .links-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 90px;
}

footer .inner .links-wrapper .wrap span{
  color: #fff; 
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px; /* 170% */
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 26px;
}


footer .inner .left p, footer .inner .links-wrapper ul li a, footer .copyright p, footer .copyright a{
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.8px; /* 170% */
  letter-spacing: -0.09px;
}

footer .inner .links-wrapper ul li{
  margin-bottom: 20px;
}

footer .copyright{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  border-top: 1px solid rgba(255,255,255,0.1);
  margin-top: 60px;
}

footer .copyright p{
  color: rgba(255,255,255,0.6);
}

footer .copyright span{
  color: #fff;
  font-weight: 500;
}

.leaderboard{
  padding-bottom: 100px;
}

.leaderboard{
  position: relative;
}

.leaderboard h2{
  font-weight: 500 !important;
  margin-bottom: 20px;
}
.table-wrap{
  overflow: auto;
}

.table-wrap table{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 16px; 
}

.table-wrap table th{
  color: #B0B7C3;
  font-size: 14px;
  padding: 10px 20px;
}

.table-wrap table td{
  background: #22282E;
  color: #B0B7C3;
  font-size: 15px;
  font-weight: 500;
  padding: 20px;
}

.lottery-details{
  padding: 50px 0 80px;
}

.lottery-details .inner{
  padding: 25px;
  display: flex;
  align-items: flex-start;
  background: #1D2833;
}

.lottery-details .inner > img{
  width: 284px;
}

.lottery-details .inner .info{
  width: calc(100% - 284px);
  padding-left: 25px;
}

.lottery-details .inner .info h3{
  font-size: 24px;
  margin-bottom: 20px;
}
.lottery-details .inner .info p{
  color: #A9AAAD;
  font-size: 15px;
  margin-bottom: 20px;
}

.lottery-details .inner .info-boxes-wrap{
  display: flex;
  margin: -15px -15px 20px;
}

.lottery-details .inner .info-box{
  flex-grow: 1;
  width: calc(20% - 30px);
  margin: 15px;
  background: #222F3B;
  padding: 12px 15px;
}

.lottery-details .inner .info-box span{
  color: #A9AAAD;
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}

.lottery-details .inner .info .info-box p{
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.lottery-details .timer{
  width: 100%;
  margin: 0;
}

.form-wrap {
  max-width: 568px;
  margin: 0 auto;
}

.form-wrap form {
  background: #1D2833;
  border-radius: 5px;
  padding: 48px;
}

.form-wrap form .form-group, .form-wrap .form-group {
  position: relative;
  margin-bottom: 15px;
}
.form-wrap .form-group label{
  color: rgba(255,255,255,0.8);
  margin-bottom: 4px;
  display: block;
  font-size: 15px;
}
.form-wrap form input,
.form-wrap form textarea,
.form-wrap form select,
.form-wrap input,
.form-wrap textarea,
.form-wrap select {
  background: #3C3F47;
  border: 0;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
  padding: 20px 35px;
  height: 70px;
  width: 100%;
}

.form-wrap input:focus,
.form-wrap textarea:focus,
.form-wrap select:focus {
  border: 0.7px solid #55cbc6;
  --tw-ring-color: none;
}

.form-wrap form .filewrap .upload-btn img, .form-wrap .filewrap .upload-btn img {
  margin-right: 5px;
}

.form-wrap form .filewrap .upload-btn, .form-wrap .filewrap .upload-btn {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 13px;
  background: #3C3F47;
  /* border: 1px solid #55cbc6; */
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 24px;
  color: #A9AAAD;
}
.form-wrap form textarea {
  height: 200px;
}

.form-wrap form .filewrap, .form-wrap .filewrap {
  position: relative;
  background-color: #3C3F47;
  border: 0;
  border-radius: 5px;
  height: 70px;
  padding: 20px 35px;
  text-align: left;
  cursor: pointer;
  margin-bottom: 15px;
  cursor: pointer;
}
.form-wrap form > label{
  color: #fff;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
}
.form-wrap form .filewrap>input, .form-wrap .filewrap>input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.form-wrap form .filewrap span, .form-wrap .filewrap span {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #949393;
  line-height: 28px;
}

.form-wrap form .filewrap .upload-btn img, .form-wrap .filewrap .upload-btn img {
  margin-right: 5px;
}

.form-wrap form .filewrap .upload-btn, .form-wrap .filewrap .upload-btn {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 13px;
  background: #3C3F47;
  /* border: 1px solid #55cbc6; */
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 24px;
  color: #A9AAAD;
}

.form-wrap form .info-wrap {
  position: absolute;
  left: calc(100% + 10px);
  top: 25px;
  width: 20px;
  height: 20px;
  display: flex;
}

.form-wrap form .info-wrap img {
  cursor: pointer;
}

.name-notice {
  position: absolute;
  left: 0;
}

.form-wrap form .info-wrap:hover .info {
  opacity: 1;
  visibility: visible;
}

.form-wrap form .info-wrap .info {
  position: absolute;
  top: 0;
  right: calc(100% + 2px);
  background: #272A33;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.2s;
  top: 10px;
  /* border: 1px solid #55cbc6; */
  width: 270px;
  z-index: 2;
  text-align: left;
  display: flex;
  align-items: flex-start;
}

.form-wrap form .info-wrap .info img {
  margin-right: 12px;
  width: 20px;
}

.form-wrap form .info-wrap .info p {
  width: calc(100% - 32px);
  word-break: break-word;
  margin-bottom: 0 !important;
  font-size: 14px !important;
}

.form-wrap h2{
  font-size: 28px !important;
  margin-bottom: 20px;
  font-weight: 500 !important;
}
.form-wrap form .checkbox-wrap {
  display: flex;
  align-items: flex-start;
}

.form-wrap form .checkbox-wrap input{
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.checkbox-wrap label a{
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.pay-upfront-head{
  text-align: center;
  max-width: 804px;
  margin: 0 auto;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 40px;
}

.pay-upfront-head h2{
  font-size: 36px !important;
  margin-bottom: 15px;
}

.pay-upfront-head p{
  color: #A9AAAD;
  font-size: 15px;
}

.life-time{
  background: rgba(255,255,255,0.05);
  border-radius: 8px;
  padding: 15px 30px;
  color: #fff;
  font-size: 24px;
  max-width: 240px;
  margin: 0 auto;
}

.life-time span{
  font-size: 14px;
}

@media  screen and (min-width:1400px) {
  .container{
      max-width: 1230px !important;
  }
}
@media  screen and (max-width:1600px) {

}

@media  screen and (max-width:1400px) {
  h1,h2{
      font-size: 54px;
  }

  .casino-intro {
      padding: 50px 0 100px;
  }

  .casino-intro .content {
      max-width: 550px;
  }

  .casino-intro canvas {
      width: 653px !important;
      margin-left: -170px;
  }
}

@media  screen and (max-width:1199px) {
  h1,h2{
      font-size: 48px;
      line-height: 1.3;
  }

  h3{
      font-size: 28px;
  }

  .casino-intro .content {
      max-width: 500px;
  }
}

@media screen and (max-width:991px) {
  .casino-intro{
      text-align: center;
  }

  .casino-intro .content {
      max-width: 550px;
      margin: 0 auto 50px;
  }

  .casino-intro p {
      margin: 0 auto 40px;
  }

  .casino-intro canvas{
      width: 100% !important;
      max-width: 600px;
      height: 312px !important;
      margin: 0 auto;
      display: block;
      position: relative;
      left: 25px;
  }

  h3{
      font-size: 22px;
  }

  header .container{
      max-width: 100%;
  }
}


@media screen and (max-width: 767px) {
  header .container{
      max-width: 100%;
  }

  header .navbar{
      flex-direction: column !important;
  }

  h1, h2 {
      font-size: 45px;
  }

  .casino-intro {
      padding: 50px 0 80px;
  }
}

@media screen and (max-width: 575px) {
  .container{
      padding: 0 30px;
  }

  header .navbar{
      flex-direction: column;
  }

  h1,h2{
      font-size: 34px;
  }

  .casino-intro canvas {
      left: 10px;
  }

  .casino-intro canvas{
      height: 212px !important;
      left: 10px;
  }
}
